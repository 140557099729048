import axios from 'axios'
import global from '@/assets/js/global'
// 登录
let loginFn = function name(params) {
    return axios.create({
        baseURL: global.apiPath,
        auth: params,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
        }
    })
}
// 配置axios实例
let http = axios.create({
  baseURL: global.apiPath
})
// request拦截器
http.interceptors.request.use(
  config => {
    // let expires = sessionStorage.getItem('token') ? sessionStorage.getItem('expires') : ''
    // let lastAccessTime = sessionStorage.getItem('token') ? sessionStorage.getItem('lastAccessTime') : ''
    // let currentTime = new Date().getTime() / 1000
    // if (Number(currentTime) - Number(lastAccessTime) > Number(expires)) {
    //   Vue.prototype.$alert('登录信息已失效，请重新登录', {
    //     confirmButtonText: '确定',
    //     callback: action => {
    //       sessionStorage.clear()
    //       router.push('/login')
    //       history.go(0)
    //     }
    //   })
    //   return
    // } else {
    //   let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '1'
    //   config.headers['X-Auth-Token'] = token
    //   config.headers['X-Requested-With'] = 'XMLHttpRequest'
    // }
    let token = sessionStorage.getItem('token') ? sessionStorage.getItem('token') : '1'
    config.headers['X-Auth-Token'] = token
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    return config
  },
  err => {
    return Promise.reject(err)
  })
// 登录
export const login = params => {
    return loginFn(params).get('/oauth2/v1/token').then(response => response)
}
// 查询当前登录用户信息
export const getUserMsg = params => {
    return http.get('/auth/oauth2/v1/me', params).then(response => response)
}
// 查询群众
export const findmembersByid = params => {
    return http.get('/auth/masses/manager/members', { params: params }).then(response => response)
}
