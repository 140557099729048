<template>
  <div class="loginBg">
    <van-nav-bar
      title="该页面请登录查看"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
    />
    <div class="loginBox">
      <van-form @submit="onSubmit">
        <van-field
          v-model="ruleForm.username"
          name="用户名"
          label="用户名"
          placeholder="用户名"
        />
        <van-field
          v-model="ruleForm.password"
          type="password"
          name="密码"
          label="密码"
          placeholder="密码"
        />
        <div style="margin: 16px; height: 30%">
          <van-button round block type="info" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import { login, getUserMsg } from "@/api/api.js";
import { findmembersNew } from "@/api/atp.js";

export default {
  data() {
    return {
      ruleForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "请填写用户名", trigger: "blur" },
          {
            trigger: "blur",
            message: "用户名格式错误",
            transform(value) {
              let uPattern = /^[a-zA-Z0-9_-]{4,16}$/;
              if (uPattern.test(value)) {
                return value;
              } else {
                return false;
              }
            },
          },
        ],
        password: [{ required: true, message: "请填写密码", trigger: "blur" }],
      },
    };
  },
  mounted() {
    // this.$toast('提示文案');
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onSubmit() {
      this.$toast.loading({
        message: "登录中...",
        forbidClick: true,
      });
      // 登录
      login(this.ruleForm)
        .then((res) => {
          if (res.status === 200) {
            sessionStorage.setItem("expires", res.data.expires);
            sessionStorage.setItem("lastAccessTime", res.data.lastAccessTime);
            sessionStorage.setItem("token", res.data.token);
            // 登陆成功后获取用户信息
            getUserMsg()
              .then((res) => {
                sessionStorage.setItem("users", JSON.stringify(res.data));
                if (res.status === 200) {
                  this.$toast.clear();
                  this.$toast("登录成功");
                  // this.$router.push("/home");
                  // if (res.data.tenants.length) {
                  //   for (let i = 0; i < res.data.tenants.length; i++) {
                  //     if (res.data.tenants[i].isDefault === true) {
                  //       sessionStorage.setItem(
                  //         "tenant",
                  //         JSON.stringify(res.data.tenants[i])
                  //       );
                  //       return;
                  //     }
                  //   }
                  // } else {
                  //   sessionStorage.setItem("tenant", JSON.stringify({}));
                  // }
                  sessionStorage.setItem(
                    "XSRFTOKEN",
                    this.getCookie("XSRF-TOKEN")
                  );
                  findmembersNew({
                    idCard: JSON.parse(sessionStorage.getItem("postForm"))
                      .idCard,
                  })
                    .then((res) => {
                      if (res.status === 200) {
                        let infoTenantCode = res.data.tenantCode;
                        let postForm = JSON.parse(
                          sessionStorage.getItem("postForm")
                        );
                        postForm.tenantCode = infoTenantCode;
                        sessionStorage.setItem(
                          "postForm",
                          JSON.stringify(postForm)
                        );
                        let userTenantCode = JSON.parse(
                          sessionStorage.getItem("users")
                        ).tenantCode;
                        if (userTenantCode.length === 6) {
                          this.$router.push("/home");
                        } else if (userTenantCode.length === 9) {
                          if (
                            infoTenantCode.substring(0, 9) === userTenantCode
                          ) {
                            this.$router.push("/home");
                          } else {
                            this.$toast("暂无此权限");
                          }
                        } else if (userTenantCode.length === 12) {
                          if (
                            infoTenantCode.substring(0, 12) === userTenantCode
                          ) {
                            this.$router.push("/home");
                          } else {
                            this.$toast("暂无此权限");
                          }
                        }
                      } else {
                        this.$toast("获取权限失败");
                      }
                    })
                    .catch(() => {
                      this.$toast("获取权限失败");
                    });
                } else {
                  this.$toast("获取用户信息失败");
                }
              })
              .catch((err) => {
                this.common.statusFn(err.response.status, "获取用户信息");
              });
          } else {
            this.$toast("登录失败");
          }
        })
        .catch((err) => {
          this.common.statusFn(err.response.status, "登录");
        });
    },
    getCookie(name) {
      // eslint-disable-next-line
      var arr,
        reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      // eslint-disable-next-line
      if ((arr = document.cookie.match(reg))) {
        return unescape(arr[2]);
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped>
/* vant 导航栏自适应 */
.van-nav-bar {
  height: 0.46rem;
}
 .van-nav-bar__content {
  height: 100%;
}
 .van-nav-bar__title {
  height: 100%;
  line-height: 0.46rem;
  font-size: 0.16rem;
}
 .van-icon-arrow-left,
 .van-nav-bar__text {
  font-size: 0.14rem;
}
.loginBox {
  margin-top: 2.3rem;
}
.van-form {
  height: 1.5rem;
}
.van-cell {
  height: 30%;
}
 .van-cell__title {
  width: 25%;
  text-align: center;
}
 .van-cell__title > span {
  display: inline-block;
  height: 100%;
  font-size: 0.14rem;
  line-height: 0.3rem;
}
 .van-field__body,
 .van-field__control {
  text-indent: 0.1rem;
  font-size: 0.14rem;
  height: 100%;
}
.van-button {
  height: 100%;
  font-size: 0.16rem;
}
.loginBg {
  background: url("../../assets/img/bg.png") no-repeat 0.9rem 0.6rem;
  background-size: 50%;
}
</style>